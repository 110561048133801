//---------------------------------------------------------------------------
// Document Ready!
//---------------------------------------------------------------------------

$(function () {

  let checkSize = function () {
    showMobileMenu()
  }
  checkSize()

  $(window).on('resize', checkSize)
  $(window).on('load', checkSize)

  $('header.global .hamburger').on('click', function () {
    if ($(this).hasClass('open')) {
      closeMenu()
    } else {
      openMenu()
    }
    return false
  })


//---------------------------------------------------------------------------
// Mobile Nav Menu
//---------------------------------------------------------------------------

  function openMenu() {
    $('body').addClass('disabled')
    $('header.global').addClass('open')
    $('header.global nav').addClass('open')
    $('header.global .hamburger').addClass('open')
  }

  function closeMenu() {
    $('body').removeClass('disabled')
    $('header.global').removeClass('open')
    $('header.global nav').removeClass('open')
    $('header.global .hamburger').removeClass('open')
  }

  function mobileMenu() {
    closeMenu()
    $('header.global nav ul li a').on('click', function () {
      closeMenu()
    })
    $('main').on('click', function () {
      if ($('body').hasClass('disabled')) {
        closeMenu()
      }
    })
  }

  function showMobileMenu() {
    const screenWidth = $(window).width()
    var mobileView = '719'
    if (screenWidth <= mobileView) {
      mobileMenu()
      $('body').removeClass('full')
      $('body').addClass('small')
    } else {
      $('body').addClass('full')
      $('body').removeClass('small')
    }
  }

//---------------------------------------------------------------------------
// Sets up scrolling behaviour
//---------------------------------------------------------------------------

  let previousScroll = 0;

  $(window).on('scroll', function () {
    // Check Direction
    let currentScroll = $(this).scrollTop();

    // Menu Home behaviour
    let reachTop = $('header.global').offset().top
      , popTop = $(window).height() - 200
      , toTheTop = $(window).scrollTop()
      , fromTheTop = $(window).scrollTop();

    if (reachTop <= toTheTop) {

      $('body.full header.global').addClass('sticky');
      $('body.small header.global').addClass('sticky');

      if (currentScroll >= (popTop)) {
        if (currentScroll > previousScroll) { // Down
          $('body.full header.global').addClass('goUp');
          $('body.full header.global').removeClass('goDown');
        } else { // UP
          $('body.full header.global').removeClass('goUp');
          $('body.full header.global').addClass('goDown');
        }
      }

    }
    if (popTop >= fromTheTop) {
      $('body.full header.global').removeClass('sticky');
      $('body.small header.global').removeClass('sticky');
      $('body.full header.global').removeClass('goUp');
    }

    previousScroll = currentScroll;
  });


//---------------------------------------------------------------------------
// Mobile Check
//---------------------------------------------------------------------------

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    $('body').addClass('mobile')
  }

//---------------------------------------------------------------------------
// Menu Vars
//---------------------------------------------------------------------------

  var menuItem = $('.menu li .menu-item')
  var menuSub = $('.menu li .menu-sub')
  var menuSubItem = $('.menu li .menu-sub-item')

//---------------------------------------------------------------------------
// Sub Menu
//---------------------------------------------------------------------------

  menuSubItem.on('focusin', function () {
    menuSub.removeClass('open')
    menuItem.removeClass('open')
    $(this).closest('.menu-sub').parent().children('.menu-item').addClass('open')
    $(this).closest('.menu-sub').addClass('open')
  })

  menuItem.on('focusin mouseenter', function () {
    menuSub.removeClass('open')
    menuItem.removeClass('open')
    $(this).addClass('open')
    $(this).parent().children('.menu-sub').addClass('open')
  })

  menuItem.on('focusout', function () {
    $(this).parent().children('.menu-sub').removeClass('open')
  })

  $('.menu').on('mouseleave', function () {
    menuSub.removeClass('open')
    menuItem.removeClass('open')
  })

});




